export const EncryptedIcon = () => {
  return (
    // <a
    //   className="encrypted-icon tooltip"
    //   href="https://blog.excalidraw.com/end-to-end-encryption/"
    //   target="_blank"
    //   rel="noopener noreferrer"
    //   aria-label={t("encrypted.link")}
    // >
    //   <Tooltip label={t("encrypted.tooltip")} long={true}>
    //     {shield}
    //   </Tooltip>
    // </a>
    <></>
  );
};
